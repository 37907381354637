import React from 'react';
import qs from "qs";
import numeral from "numeral";

import './Success.css';
import { Link } from 'react-router-dom';

const Success = (props) => {

    if(!props.match.params.id) {
        props.history.replace('/')
    }
	const data = JSON.parse(localStorage.getItem(props.match.params.id));

    if(!data && !data.amount) {
        props.history.replace('/')
    }
    return (
        <div className="success-container">
            <div className="item-contaienr">
            <div className="currency-values">
					<p>PaymentId :</p>
					<p style={{ fontSize: "12px", alignSelf: "center" }}>
						{props.match.params.id}
					</p>
				</div>
				<div className="currency-values">
					<p>Amount :</p>
					<p>{numeral(data.amount).format("$0,0.00")}</p>
				</div>
				<div className="currency-values">
					<p>Currency :</p>
					<p>{data.currency}</p>
				</div>
                <p className="success">
                    Succeeded
                </p>
                <Link to="/transactionlist" className="transaction-btn">Transactions</Link>
            </div>
        </div>
    );
}

export default Success;
