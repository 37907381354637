import React, { useCallback, useContext, useState } from "react";
import qs from "qs";
import Axios from "axios";
import numeral from "numeral";

import "./Payment.css";
import { loadingCon } from "../../App";
import { Link } from "react-router-dom";
import { checkAuth } from "../../utils";

const Payment = (props) => {
	const [result, setResult] = useState("");

	const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
	const { setLoading } = useContext(loadingCon);
	if (!params.transaction_id && !params.pm_id) {
		props.history.replace("/");
	}

	const onExcute = useCallback(() => {
		const data = {
			transaction_id: params.transaction_id,
			order_id: params.order_id,
			additionalProp1: {},
		};
		setLoading(true);
		Axios({
			headers: {
				Authorization: checkAuth()
			},
			method: "post",
			url: "https://api.globalsms.io/payments/payssion/getTXDetails",
			data,
		})
			.then((response) => {
				const data = response.data;
				console.log(response.data);
				
				if (data.success && data.payload && data.payload.result && data.payload.result.transaction) {
					setResult(data.payload.result.transaction)
				}
			})
			.catch((error) => {
				localStorage.removeItem('authToken')
				props.history.replace('/')
			})
			.finally(() => {
				setLoading(false);
			});
	});

	return (
		<div className="container">
			<div className="item-contaienr">
				{!result && (
					<>
						<div className="currency-values">
							<p>transaction_id :</p>
							<p style={{ fontSize: "12px", alignSelf: "center" }}>
								{params.transaction_id}
							</p>
						</div>
						<div className="currency-values">
							<p>order_id :</p>
							<p style={{ fontSize: "12px", alignSelf: "center" }}>
								{params.order_id}
							</p>
						</div>
						<div className="currency-values">
							<p>Amount :</p>
							<p>{numeral(params.amount).format("$0,0.00")}</p>
						</div>
						<div className="currency-values">
							<p>Currency :</p>
							<p>{params.currency}</p>
						</div>
					</>
				)}
				{result && (
					<>
						<div className="currency-values">
							<p>transaction_id :</p>
							<p style={{ fontSize: "12px", alignSelf: "center" }}>{result.transaction_id}</p>
						</div>
						<div className="currency-values">
							<p>amount :</p>
							<p>{result.amount}</p>
						</div>
						<div className="currency-values">
							<p>currency :</p>
							<p>{result.currency}</p>
						</div>
						<div className="currency-values">
							<p>order_id :</p>
							<p style={{ fontSize: "12px", alignSelf: "center" }}>{result.order_id}</p>
						</div>
						<div className="currency-values">
							<p>paid :</p>
							<p>{result.paid}</p>
						</div>
						<div className="currency-values">
							<p>net :</p>
							<p>{result.net}</p>
						</div>
						<div className="currency-values">
							<p>state :</p>
							<p>{result.state}</p>
						</div>
						<div className="currency-values">
							<p>fees :</p>
							<p>{result.fees}</p>
						</div>
						<div className="currency-values">
							<p>fees_add :</p>
							<p>{result.fees_add}</p>
						</div>
						<div className="currency-values">
							<p>net :</p>
							<p>{result.net}</p>
						</div>
					</>
				)}
				<button className="payment-btn" onClick={onExcute}>
					Verify
				</button>
				<Link to="/payssiontransactionlist" className="transaction-btn">
					Transactions
				</Link>
			</div>
		</div>
	);
};

export default Payment;
