import React from 'react';
import {
    BrowserRouter, Switch, Route,

} from 'react-router-dom';

import Home from './pages/Home';
import Payment from './pages/Payment';
import Cancel from './pages/Cancel';
import Success from './pages/Success';
import TransactionList from './pages/TransactionList';
import PHome from './pages/payssion/Home';
import PPayment from './pages/payssion/Payment';
import PTransactionList from './pages/payssion/TransactionList';
import Login from './pages/login/Login';
import PrivateRoute from './components/PrivateRoute';



const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" component={Home} exact />
                <PrivateRoute path="/paymentsuccess" component={Payment} />
                <PrivateRoute path="/paymentcancel" component={Cancel} />
                <PrivateRoute path="/success/:id" component={Success} />
                <PrivateRoute path="/transactionlist" component={TransactionList} />
                <PrivateRoute path="/payssion" component={PHome} exact />
                <PrivateRoute path="/payssionverify" component={PPayment} />
                <PrivateRoute path="/payssiontransactionlist" component={PTransactionList} />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
