import React, { useCallback, useContext, useEffect, useState } from "react";
import Axios from "axios";
import numeral from "numeral";

import { loadingCon } from "../../App";
import "./TransactionList.css";
import { checkAuth } from "../../utils";

const TransactionList = ({history}) => {
    const { setLoading } = useContext(loadingCon);
    const [data, setData] = useState([])

	useEffect(() => {
		getTransactions();
	}, []);

	const getTransactions = useCallback(() => {
		setLoading(true);
		Axios({
			headers: {
				Authorization: checkAuth()
			},
			method: "get",
			url: "https://api.globalsms.io/payments/payssion/getTXList",
		})
			.then((response) => {
                const data = response.data;
                if(data.payload && data.payload.result && data.payload.result.transactions) {
                    setData(data.payload.result.transactions)
                }
				console.log(data);
			})
			.catch((error) => {
				localStorage.removeItem('authToken')
				history.replace('/')
			})
			.finally(() => {
				setLoading(false);
			});
	});
	return (
		<div className="transaction-container">
			<div className="transaction-tabel-container">
				<table>
                    <thead>
					<tr>
						<th>#</th>
						<th>transaction_id</th>
						<th>order_id</th>
						<th>Amount</th>
						<th>Currency</th>
						<th>Status</th>
						<th>Created date</th>
					</tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item, index) => {
                                return (
                                    <tr key={item.transaction_id}>
                                        <td>{index + 1}</td>
                                        <td style={{fontSize: '9px'}}>{item.transaction_id}</td>
                                        <td style={{fontSize: '9px'}}>{item.order_id}</td>
                                        <td>{numeral(item.amount).format("$0,0.00")}</td>
                                        <td>{item.currency}</td>
                                        <td>{item.state}</td>
                                        <td style={{fontSize: '9px'}}>{item.create_date ? item.create_date : '-'}</td>
                                    </tr>
                                )
                            }) 
                        }
                    </tbody>
				</table>
			</div>
		</div>
	);
};

export default TransactionList;
