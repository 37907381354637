import React, { useState, useCallback, useContext } from "react";
import "./Login.css";
import axios from "axios";
import { loadingCon } from "../../App";

const Login = ({history}) => {
    const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
    
	const [password, setPassword] = useState("");
	const { setLoading } = useContext(loadingCon);

	const onSubmitLogin = useCallback(() => {
		const serverData = {
			email,
			password,
			additionalProp1: {},
		};
		setLoading(true);
		axios({
			method: "post",
			url: "https://api.globalsms.io/payments/user/auth",
			data: serverData,
		})
			.then((response) => {
				const data = response.data;
				if (data.payload ) {
                    console.log(data.payload);
					localStorage.setItem('authToken', 'JWT ' + data.payload.token)
                    history.replace('/')
				}
			})
			.catch((error) => {
				console.log(error);
				
				setError(true)
			})
			.finally(() => {
				setLoading(false);
			});
	});

	return (
		<div className="login-container">
			<div className="item-contaienr">
				<input
					type="text"
					placeholder="Example@email.com"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className="currency-input"
				/>
                				<input
					type="password"
					placeholder="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className="currency-input"
				/>
                {error && <p style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    fontSize: '18px',
                    color: 'rgb(241, 66, 75)',
                    marginBottom: '4px'
                }}>Wrong password and email combination</p>}
				<button className="login-btn" onClick={onSubmitLogin}>
					Login
				</button>
			</div>
		</div>
	);
};

export default Login;
