import React, { useState, useCallback, useContext } from "react";
import numeral from "numeral";
import "./Home.css";
import axios from "axios";
import { loadingCon } from "../App";
import { Link } from "react-router-dom";
import { checkAuth } from "../utils";
import Navbar from "../components/Navbar/Navbar";

const Home = ({history}) => {
	const [amount, setAmount] = useState("");
	const [currency, setCurrency] = useState("USD");
    const {setLoading} = useContext(loadingCon);

	const onAmountChange = useCallback(
		(e) => {
			const amount = e.target.value;
			if (amount.match(/^\d*(\.\d{0,2})?$/)) {
				setAmount(() => amount);
			}
		},
		[setAmount]
	);

	const onSubmitAmount = useCallback(() => {
        const serverData = {
			amount: amount,
			intent: "sale",
			currency: currency,
			description: "test",
			additionalProp1: {},
		};
        setLoading(true);
        axios({
            headers: {
                Authorization : checkAuth()
            },
            method: 'post',
            url: 'https://api.globalsms.io/payments/paypal/createPayment',
            data: serverData
        }).then((response)=> {
            const data = response.data
            if(data.payload && data.payload.result) {
                console.log(data.payload.result);
                localStorage.setItem(data.payload.result.paymentID, JSON.stringify(serverData))
                window.location = data.payload.result.approval_url
            }
            
        }).catch((error)=> {
			localStorage.removeItem('authToken')
			history.replace('/')
            
        }).finally(()=> {
            setLoading(false)
        })

	});

	return (
		<div className="home-container">
			<Navbar />
			<div className="item-contaienr">
				<input
					type="text"
					placeholder="Amount"
					value={amount}
					onChange={onAmountChange}
					className="currency-input"
				/>
				<select
					value={currency}
					onChange={setCurrency}
					className="currency-input"
				>
					<option value="USD">USD</option>
				</select>
				<p className="amount">{numeral(amount).format("$0,0.00")}</p>

				<button
					className="payment-btn"
					onClick={onSubmitAmount}
				>
					Pay
				</button>

                <Link to="/transactionlist" className="transaction-btn">Transactions</Link>
			</div>
		</div>
	);
};

export default Home;
