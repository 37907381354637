import React, { useCallback, useContext } from "react";
import qs from "qs";
import Axios from "axios";
import numeral from "numeral";

import "./Payment.css";
import { loadingCon } from "../App";
import { Link } from "react-router-dom";
import { checkAuth } from "../utils";

const Payment = (props) => {
	const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
	const { setLoading } = useContext(loadingCon);
	if (!params.paymentId && !params.PayerID) {
		props.history.replace("/");
	}
	const data = JSON.parse(localStorage.getItem(params.paymentId));

	const onExcute = useCallback(() => {
		const data = {
			paymentID: params.paymentId,
			payerID: params.PayerID,
			additionalProp1: {},
		};
		setLoading(true);
		Axios({
			headers: {
				Authorization: checkAuth()
			},
			method: "post",
			url: "https://api.globalsms.io/payments/paypal/executePayment",
			data,
		})
			.then((response) => {
                const data = response.data;
                if(data.success) {
                    props.history.replace(`/success/${params.paymentId}`)
                }
			})
			.catch((error) => {
				localStorage.removeItem('authToken')
				props.history.replace('/')
			})
			.finally(() => {
				setLoading(false);
			});
	});

	return (
		<div className="container">
			<div className="item-contaienr">
				<div className="currency-values">
					<p>PaymentId :</p>
					<p style={{ fontSize: "12px", alignSelf: "center" }}>
						{params.paymentId}
					</p>
				</div>
				<div className="currency-values">
					<p>PayerId :</p>
					<p style={{ fontSize: "12px", alignSelf: "center" }}>
						{params.PayerID}
					</p>
				</div>
				<div className="currency-values">
					<p>Amount :</p>
					<p>{numeral(data.amount).format("$0,0.00")}</p>
				</div>
				<div className="currency-values">
					<p>Currency :</p>
					<p>{data.currency}</p>
				</div>
				<button className="payment-btn" onClick={onExcute}>
					Confirm
				</button>
                <Link to="/transactionlist" className="transaction-btn">Transactions</Link>
			</div>
		</div>
	);
};

export default Payment;
