import React from 'react';
import './Cancel.css';
import { Link } from 'react-router-dom';

const Cancel = () => {
    return (
        <div className="cancel-container">
            <div className="item-contaienr">
                <p>
                    Canceled
                </p>
                <Link to="/transactionlist" className="transaction-btn">Transactions</Link>
            </div>
        </div>
    );
}

export default Cancel;
