import React from "react";
import { NavLink } from "react-router-dom";
import './Navbar.css'
const Navbar = () => {
	return (
		<div className="navbar-container ">
			<NavLink to="/" className='navbar-item' exact activeClassName='navbar-active'>paypal</NavLink>
			<NavLink to="/payssion" className='navbar-item' activeClassName='navbar-active'>payssion</NavLink>
		</div>
	);
};

export default Navbar;
