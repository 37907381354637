import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { checkAuth } from '../utils';

const PrivateRoute = ({component : Component, ...rest}) => {
    console.log(checkAuth());
    
    return (
        <Route
            {...rest}
            render={props => (
                checkAuth() ?  <Component {...props} /> :  <Redirect to="/login" />
            )}
        />
    );
}

export default PrivateRoute;
