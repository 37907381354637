import React, { useContext } from "react";
import ReactLoader from "react-loader-spinner";

import "./Loader.css";
import { loadingCon } from "../App";

const Loader = () => {
	const { loading } = useContext(loadingCon);

	return (
		<>
			{loading && (
				<div className="overlay">
					<ReactLoader
						className="loader"
						type="MutatingDots"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</div>
			)}
		</>
	);
};

export default Loader;
