import React, { useState } from 'react';
import Loader from './components/Loader';
import Router from './Router';

const loadingContext = React.createContext()

export const loadingCon = loadingContext

const App = () => {
    const [loading, setLoading] = useState(false);
    return (
        <loadingContext.Provider value={{loading, setLoading}}>
            <Loader />
            <Router />
        </loadingContext.Provider>
    );
}

export default App;

